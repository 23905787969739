<template>
  <div>
    <!-- 表单 -->
    <van-form ref="form">
      <!-- 基本信息 -->
      <van-cell-group title="基本信息">
        <van-field v-model="form.stuNo" placeholder="请填写学号" :disabled="true" label="学号" />
        <van-field v-model="form.stuName" placeholder="请填写姓名" :disabled="true" label="姓名" />
        <custom-radio v-model="form.sex" label="性别" :disabled="true" :columns="sexDict" />
        <custom-date-picker v-model="form.bornTime" :disabled="true" label="出生日期" placeholder="点击选择出生日期" />
        <van-field v-model="form.facultyName" placeholder="请填写院系" :disabled="true" label="院系" />
        <van-field v-model="form.specName" placeholder="请填写专业" :disabled="true" label="专业" />
        <van-field v-model="form.className" placeholder="请填写班级" :disabled="true" label="班级" />
        <van-field v-model="form.tutorName" placeholder="请填写辅导员" :disabled="true" label="辅导员" />
        <van-field v-model="form.nationalitName" placeholder="民族" :disabled="true" label="民族" />
        <van-field v-model="form.politicalStatusName" placeholder="政治面貌" :disabled="true" label="政治面貌" />
        <van-field v-model="form.admissionTime" :disabled="true" label="入学年月" />
        <van-field v-model="form.cardId" :disabled="true" label="身份证号" />
        <van-field required :rules="[{ required: true, message: '请填写电话' },
        {pattern: /^1[3-9]\d{9}$/, message: '请输入合法的手机号/电话号'}]" v-model="form.phone" placeholder="请填写电话" label="联系电话" />
      </van-cell-group>

      <!-- 曾获何种奖励 -->
      <van-cell-group title="曾获奖励">
        <van-field v-model="form.honor" rows="3" class="textarea-style" arrow-direction="" label="曾获何种奖励" type="textarea" maxlength="300" placeholder="请描述曾获何种奖励" show-word-limit />
      </van-cell-group>

      <van-cell-group title="家庭经济情况">
        <van-field required :rules="[{ required: true,pattern:/^[0-9]{1,2}$/ }]" v-model="form.familyPopulation" placeholder="请填写家庭人口总数" label="家庭人口总数" />
        <van-field required :rules="[{ required: true,pattern:/^[0-9]{1,10}$/  }]" v-model="form.familyIncomeCount" placeholder="请填写家庭月总收入" label="家庭月总收入" />
        <van-field required :rules="[{ required: true  ,pattern:/^[0-9]{1,10}$/}]" v-model="form.familyIncomeMonth" placeholder="请填写人均月收入" label="人均月收入" />
        <van-field required :rules="[{ required: true  }]" v-model="form.familyIncomeSource" placeholder="请填写收入来源" label="收入来源" />
        <van-field required :rules="[{ required: true  }]" v-model="form.familyAddress" placeholder="请填写家庭住址" label="家庭住址" />
        <van-field required :rules="[{ required: true  }]" v-model="form.familyPostCode" placeholder="请填写邮政编码" label="邮政编码" />
      </van-cell-group>

      <!-- 学习成绩 -->
      <van-cell-group title="学习成绩">
        <van-field required v-model="form.scoreGradesRanking" :rules="[{ required: true}]" placeholder="请输入（名次/总人数）" label="成绩排名" />
        <van-field required v-model="form.scoreObligatory" :rules="[{ required: true,pattern:/^[0-9]{1,3}$/ }]" placeholder="必修课" label="必修课门数" />
        <van-field required v-model="form.scoreObligatoryPass" :rules="[{ required: true,pattern:/^[0-9]{1,3}$/ }]" placeholder="及格以上门数" label="及格以上门数" />
        <custom-radio v-model="form.isComplex" :rules="[{ required: true}]" label="实行综合考评" :columns="isNotDict" />
        <van-field required v-if="form.isComplex" :rules="[{ required: true}]" v-model="form.complexGradesRanking" placeholder="请输入（名次/总人数）" label="综合考评排名" />
      </van-cell-group>
      <!-- 申请理由 -->
      <van-cell-group title="申请理由">
        <van-field required v-model="form.applyReason" :rules="[{ required: true  } ]" rows="3" class="textarea-style" arrow-direction="" label="申请理由" type="textarea" maxlength="300" placeholder="请填写申请理由" show-word-limit />
      </van-cell-group>

      <!-- 证明附件 -->
      <van-cell-group title="证明附件" class="upload-file" style="margin-bottom:65px;">
        <file-upload v-if="refsh" accept=".doc,.docx,.pdf,.xls,.xlsx,.png,.gif,.jpeg" v-model="form.fileId" miz="JZGL" biz="storage" :multiple="3" v-on:uploadResponse="uploadResponse" list-type="list" :auto-upload="true" :max-size="1">
          <span slot="tip" class="upload-tips">
            支持上传任意文件,文件最大1M
          </span>
        </file-upload>
      </van-cell-group>
      <!-- 底部 -->
      <div class="submit-button shadow-style" v-if="form.status === 1 || form.status === '1'">
        <div>
          <van-button round block type="primary" :loading="loading" @click="doSubmit(true)">提 交</van-button>
        </div>
      </div>
      <div class="submit-save shadow-style" v-else>
        <van-button round block type="info" class="submit-save-savebtn" color="rgba(235, 123, 77, 1)" :loading="loading" @click="doSubmit(false)">保 存</van-button>
        <van-button round block type="primary" class="submit-save-subbtn" :loading="loading" @click="doSubmit(true)">提 交</van-button>
      </div>
    </van-form>

  </div>
</template>

<script>
import { add, edit, getDtoById } from "@/api/modules/award/inspirationJxj";
import { getStuinfo } from "@/api/modules/student/stuInfo";
import { mapGetters } from "vuex";
import fileUpload from "@/components/FileUpload";

export default {
  name: "InspirationalBonus",
  components: { fileUpload },
  dicts: ["xj_xb", "xj_nj", "ctry_base_polit_stat", "nation_roman_alph_name"],
  data() {
    return {
      sexDict: [
        { label: "男", value: "1" },
        { label: "女", value: "2" },
      ],
      isNotDict: [
        { label: "是", value: true },
        { label: "否", value: false },
      ],
      loading: false,
      refsh: true,
      form: {
        id: null,
        stuName: null,
        stuNo: null,
        sex: null,
        nationality: null,
        bornTime: null,
        politicalStatus: null,
        nationalitName: null,
        politicalStatusName: null,
        admissionTime: null,
        gradeNo: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        phone: null,
        cardId: null,
        honor: null,
        familyPopulation: null,
        familyIncomeCount: null,
        familyIncomeMonth: null,
        familyIncomeSource: null,
        familyAddress: null,
        familyPostCode: null,
        scoreGradesRanking: null,
        scoreObligatory: null,
        scoreObligatoryPass: null,
        isComplex: true,
        complexGradesRanking: null,
        applyReason: null,
        tutorName: null,
        tutorNo: null,
        status: 0,
        fileId: [],
      },
    };
  },
  computed: {
    ...mapGetters(["user", "roles"]),
  },
  created() {
    if (this.$route.query && this.$route.query.id) {
      document.title = "编辑国家励志奖学金";
      //学生修改
      getDtoById(this.$route.query.id).then((res) => {
        this.form = res;

        // 强制刷新组件
        this.refsh = false;
        this.$nextTick(() => {
          this.refsh = true;

          setTimeout(() => {
            this.form.nationalitName = this.codeConvertLabel(
              res.nationality,
              this.dict.nation_roman_alph_name
            );
            this.form.politicalStatusName = this.codeConvertLabel(
              res.politicalStatus,
              this.dict.ctry_base_polit_stat
            );
          }, 100);
        });
      });
    } else {
      document.title = "新增国家励志奖学金";
      //学生新增
      getStuinfo({ stuNo: this.user.username }).then((stu) => {
        if (stu) {
          this.$nextTick(() => {
            this.updateFrom(stu);
          });
        } else {
          this.$message.error("未检索到您的学生信息！");
          return;
        }
      });
    }
  },
  methods: {
    updateFrom(stuInfo) {
      this.form.facultyNo = stuInfo.collNo; // 院系代码
      this.form.facultyName = stuInfo.collName; // 院系名称
      this.form.specNo = stuInfo.sqecCode; // 专业代码
      this.form.specName = stuInfo.sqecName; // 专业名称
      this.form.classNo = stuInfo.classNo; // 班级号
      this.form.className = stuInfo.className; // 班级名称
      this.form.stuNo = stuInfo.stuNo; // 学号
      this.form.stuName = stuInfo.stuName; // 姓名
      this.form.sex = stuInfo.sexCode; // 性别码
      this.form.sexName = stuInfo.sexName; // 性别名称
      this.form.cardId = stuInfo.cardNo; // 身份证号
      this.form.nationality = stuInfo.nationCode; //民族
      this.form.politicalStatus = stuInfo.polCode; //政治面貌
      this.form.gradeNo = stuInfo.gradeNo;
      this.form.tutorName = stuInfo.conslrStaffName; //辅导员姓名
      this.form.tutorNo = stuInfo.conslrStaffNo; //辅导员卡号
      this.form.bornTime = stuInfo.bdDate; // 出生日期
      this.form.admissionTime = stuInfo.enterMon; //入学年月
      this.form.schoolSystem = stuInfo.eduSystem; //学制
      this.form.nationalitName = stuInfo.nativePlaceName;
      this.form.politicalStatusName = stuInfo.polName;
    },
    // 文件上传后的回调
    uploadResponse(fileIds, data) {
      if (fileIds.length) {
        this.form.fileId = fileIds;
      }
    },
    doSubmit(status) {
      if (status) {
        this.$refs.form.validate().then(
          (res) => {
            // 验证通过数据提交
            this.$dialog
              .confirm({
                title: "提示",
                message: "你确定要进行提交操作吗？",
              })
              .then(() => {
                this.loading = true;
                this.form.status = 1;
                if (this.$route.query && this.$route.query.id) {
                  this.doEdit();
                } else {
                  this.doAdd();
                }
              })
              .catch(() => {
                // on cancel
              });
          },
          (err) => {
            this.$notify({
              type: "warning",
              message: "有提交项未填写！",
              duration: 1500,
            });
          }
        );
      } else {
        this.$dialog
          .confirm({
            title: "提示",
            message: "你确定要进行保存操作吗？",
          })
          .then(() => {
            this.loading = true;
            this.form.status = 0;
            if (this.$route.query && this.$route.query.id) {
              this.doEdit();
            } else {
              this.doAdd();
            }
          })
          .catch(() => {
            // on cancel
          });
      }
    },
    doAdd() {
      add(this.form)
        .then((res) => {
          this.$notify({
            message: "添加成功",
            type: "success",
            duration: 2000,
          });
          this.loading = false;
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    doEdit() {
      edit(this.form)
        .then((res) => {
          this.$notify({
            message: "修改成功",
            type: "success",
            duration: 2500,
          });
          this.loading = false;
          this.cancel();
        })
        .catch((err) => {
          this.loading = false;
        });
    },
    cancel() {
      this.form = {
        id: null,
        stuName: null,
        stuNo: null,
        sex: null,
        nationality: null,
        bornTime: null,
        politicalStatus: null,
        nationalitName: null,
        politicalStatusName: null,
        admissionTime: null,
        gradeNo: null,
        facultyNo: null,
        facultyName: null,
        specNo: null,
        specName: null,
        classNo: null,
        className: null,
        phone: null,
        cardId: null,
        honor: null,
        familyPopulation: null,
        familyIncomeCount: null,
        familyIncomeMonth: null,
        familyIncomeSource: null,
        familyAddress: null,
        familyPostCode: null,
        scoreGradesRanking: null,
        scoreObligatory: null,
        scoreObligatoryPass: null,
        isComplex: true,
        complexGradesRanking: null,
        applyReason: null,
        tutorName: null,
        tutorNo: null,
        status: 0,
        fileId: [],
      };
      this.$router.go(-1);
    },
    codeConvertLabel(code, dicts) {
      return dicts
        .filter((item) => {
          return item.value + "" === code + "";
        })
        .map((item) => {
          return item.label;
        })
        .toString();
    },
  },
};
</script>
<style lang="scss" scoped>
.van-field__label {
  width: 110px;
}
.van-cell {
  font-size: 15px;
  line-height: 32px;
  height: 50px;
}
.van-cell__left-icon,
.van-cell__right-icon {
  line-height: 32px;
}
.textarea-style {
  height: 100% !important;
}

::v-deep.submit-save {
  background-color: #fff;
  position: fixed;
  bottom: 0;
  width: 100%;
  line-height: var(--footer-height);
  text-align: center;
  &-subbtn {
    margin-left: 5%;
  }

  .van-button {
    margin-top: 10px;
    margin-bottom: 9px;
    width: 40%;
  }

  .van-button--block {
    display: inline-block !important;
  }
}
</style>